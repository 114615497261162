<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!employeeInformation">
      Agregar personal
    </h1>

    <div v-if="!employeeInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div
      v-if="
        !employeeInformation ||
        (companyPaymentPlanModules &&
          companyPaymentPlanModules.humanResources.seeComissionsInformation ===
            'S' &&
          permissions &&
          permissions.humanResources.seeComissionsInformation === 'S')
      "
    >
      <h3 class="form-division">Información del tipo de usuario</h3>

      <div class="global-form-hint-container">
        <p class="global-form-hint">
          Para poder seleccionar un tipo es necesario ligar el personal a un
          usuario, seleccionandolo en el recuadro a continuación
        </p>
      </div>

      <b-field grouped class="special-margin">
        <b-field label="Usuario (Opcional)" expanded label-position="inside">
          <b-select
            placeholder="Ejemplo: Vendedor"
            expanded
            icon="card-account-details-outline"
            v-model="newEmployee.USER"
          >
            <option :value="undefined">Seleccione un usuario</option>
            <option
              v-for="singleUser in users"
              :key="singleUser._id"
              :value="singleUser._id"
              :disabled="checkDisabledUser(singleUser)"
            >
              {{ singleUser.email }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Tipo (Opcional)" expanded label-position="inside">
          <b-select
            placeholder="Ejemplo: Vendedor"
            expanded
            icon="account-group-outline"
            v-model="newEmployee.TIPO"
            :disabled="!newEmployee.USER"
          >
            <option value="">Seleccione un tipo</option>
            <option value="VE">Vendedor</option>
            <option value="CO">Comprador</option>
            <option value="VC">Vendedor/Comprador</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field
        grouped
        class="special-margin"
        v-if="newEmployee.TIPO === 'CO' || newEmployee.TIPO === 'VC'"
      >
        <b-field
          label="Porcentaje de comision de compra (Opcional)"
          expanded
          label-position="inside"
        >
          <PercentageInput
            placeholder="Ejemplo: 4"
            expanded
            type="number"
            step="any"
            icon="cash"
            v-model="newEmployee.COMISION_COMPRA"
          />
        </b-field>
        <b-field
          label="Tipo de comisión de compra (Opcional)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Al recibir material"
            expanded
            icon="clipboard-arrow-down-outline"
            v-model="newEmployee.TIPO_COMISION_COMPRA"
          >
            <option value="" disabled>Seleccione un tipo de comisión</option>
            <option value="CO">Al realizar compra</option>
            <option value="RE">Al recibir material</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field
        grouped
        class="special-margin"
        v-if="newEmployee.TIPO === 'VE' || newEmployee.TIPO === 'VC'"
      >
        <b-field
          label="Porcentaje de comision de venta (Opcional)"
          expanded
          label-position="inside"
        >
          <PercentageInput
            placeholder="Ejemplo: 4"
            expanded
            type="number"
            step="any"
            icon="cash"
            v-model="newEmployee.COMISION_VENTA"
          />
        </b-field>
        <b-field
          label="Tipo de comisión de venta (Opcional)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Al recibir material"
            expanded
            icon="clipboard-arrow-down-outline"
            v-model="newEmployee.TIPO_COMISION_VENTA"
          >
            <option value="" disabled>Seleccione un tipo de comisión</option>
            <option value="VE">Al realizar venta</option>
            <option value="CO">Al recibir pago de venta</option>
          </b-select>
        </b-field>
      </b-field>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de nacimiento (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': errors.FECHA_NACIMIENTO }"
        :message="{
          'La fecha de nacimiento no es valida ': errors.FECHA_NACIMIENTO,
        }"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newEmployee.FECHA_NACIMIENTO"
          required
          class="required-input"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de inico (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': errors.FECHA_INICIO }"
        :message="{
          'La fecha de inicio no es valida ': errors.FECHA_INICIO,
        }"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newEmployee.FECHA_INICIO"
          required
          class="required-input"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de personal (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.CVE_PERS }"
        :message="{
          'La clave de personal no es valida': errors.CVE_PERS,
        }"
      >
        <b-input
          :disabled="employeeInformation"
          placeholder="Ejemplo: Trabajador 1"
          type="text"
          icon="account"
          v-model="newEmployee.CVE_PERS"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Puesto (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.PUESTO }"
        :message="{
          'El pusto no es valido': errors.PUESTO,
        }"
      >
        <b-input
          placeholder="Ejemplo Gerente"
          type="text"
          icon="account-hard-hat"
          v-model="newEmployee.PUESTO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.NOMBRE }"
        :message="{
          'El nombre no es valido': errors.NOMBRE,
        }"
      >
        <b-input
          placeholder="Ejemplo: Michael Scott"
          type="text"
          icon="card-account-details-outline"
          v-model="newEmployee.NOMBRE"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Apellido paterno (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.PATERNO }"
        :message="{
          'El apellido paterno no es valido': errors.PATERNO,
        }"
      >
        <b-input
          placeholder="Ejemplo: García"
          type="text"
          icon="human-male-boy"
          v-model="newEmployee.PATERNO"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Apellido materno (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.MATERNO }"
        :message="{
          'El pusto no es valido': errors.MATERNO,
        }"
      >
        <b-input
          placeholder="Ejemplo Ramírez"
          type="text"
          icon="human-female-girl"
          v-model="newEmployee.MATERNO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="CURP (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.CURP }"
        :message="{
          'El curp no es valido': errors.CURP,
        }"
      >
        <b-input
          placeholder="Ejemplo: MAHJ280603MSPRRV09"
          type="text"
          icon="id-card"
          v-model="newEmployee.CURP"
          v-cleave="masks.customCURP"
          required
        ></b-input>
      </b-field>
      <b-field
        label="RFC (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.RFC }"
        :message="{
          'El RFC no es valido': errors.RFC,
        }"
      >
        <b-input
          placeholder="Ejemplo: VECJ880326000"
          type="text"
          icon="credit-card"
          v-model="newEmployee.RFC"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Precio de hora extra (Opcional)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          icon="currency-usd"
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 100"
          :required="false"
          v-model="newEmployee.PRECIO_HORA_EXTRA"
          :disabled="false"
        >
        </MoneyInput>
      </b-field>
      <b-field
        label="Cantidad máxima a prestar (Opcional)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          icon="currency-usd"
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 2000"
          :required="false"
          v-model="newEmployee.CANTIDAD_MAXIMA_PRESTAMO"
          :disabled="false"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div
      v-if="
        !employeeInformation ||
        (companyPaymentPlanModules &&
          companyPaymentPlanModules.humanResources.seeComissionsInformation ===
            'S' &&
          permissions &&
          permissions.humanResources.seeComissionsInformation === 'S')
      "
    >
      <b-field grouped class="special-margin">
        <b-field
          label="Salario fijo (Opcional)"
          expanded
          label-position="inside"
        >
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 5000"
            expanded
            icon="cash-100"
            v-model="newEmployee.SALARIO_FIJO"
          />
        </b-field>

        <b-field
          label="Meta mensual de ventas netas (Opcional)"
          expanded
          label-position="inside"
        >
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 5000"
            expanded
            icon="cash-100"
            v-model="newEmployee.META_VENTAS"
          />
        </b-field>
      </b-field>

      <b-checkbox
        v-model="newEmployee.SALARIO_ES_PRESTAMO"
        true-value="S"
        false-value="N"
        class="permissions-checkbox first-row-checkbox special-margin"
      >
        ¿Salario fijo es un prestamo?
      </b-checkbox>
    </div>

    <div class="block special-margin">
      <h3 class="form-division">Sexo</h3>
      <b-radio v-model="newEmployee.SEXO" name="employeeSex" native-value="M">
        Masculino
      </b-radio>
      <b-radio v-model="newEmployee.SEXO" name="employeeSex" native-value="F">
        Femenino
      </b-radio>
      <b-radio v-model="newEmployee.SEXO" name="employeeSex" native-value="N">
        No especificar
      </b-radio>
    </div>

    <h3 class="form-division">Horario</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Horario de entrada (Opcional)"
        expanded
        label-position="inside"
      >
        <b-timepicker
          hour-format="24"
          locale="es-MX"
          placeholder="Horario de entrada"
          icon="calendar-today"
          v-model="newEmployee.HORARIO_ENTRADA"
          trap-focus
        >
        </b-timepicker>
      </b-field>
      <b-field
        label="Horario de salida (Opcional)"
        expanded
        label-position="inside"
      >
        <b-timepicker
          hour-format="24"
          locale="es-MX"
          placeholder="Horario de salida"
          icon="calendar-today"
          v-model="newEmployee.HORARIO_SALIDA"
          trap-focus
        >
        </b-timepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Horario de desayuno (Opcional)"
        expanded
        label-position="inside"
      >
        <b-timepicker
          hour-format="24"
          locale="es-MX"
          placeholder="Horario de desayuno"
          icon="calendar-today"
          v-model="newEmployee.HORARIO_DESAYUNO"
          trap-focus
        >
        </b-timepicker>
      </b-field>
      <b-field
        label="Horario de comida (Opcional)"
        expanded
        label-position="inside"
      >
        <b-timepicker
          hour-format="24"
          locale="es-MX"
          placeholder="Horario de comida"
          icon="calendar-today"
          v-model="newEmployee.HORARIO_COMIDA"
          trap-focus
        >
        </b-timepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Horario de cena (Opcional)"
        expanded
        label-position="inside"
      >
        <b-timepicker
          hour-format="24"
          locale="es-MX"
          placeholder="Horario de cena"
          icon="calendar-today"
          v-model="newEmployee.HORARIO_CENA"
          trap-focus
        >
        </b-timepicker>
      </b-field>
    </b-field>

    <h3 class="form-division">Dirección</h3>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Código Postal (Opcional)
          <b-tooltip
            multilined
            label="Al llenar el código postal la información de estado, municipio, ciudad y colonia es autocompletada"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Ejemplo: 00000"
          expanded
          icon="directions-fork"
          v-model="newEmployee.CP"
          v-cleave="masks.customCP"
          @input="(value) => searchForDirectionInformation(value)"
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Dirección (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Paseo del árbol #229"
          expanded
          icon="file-outline"
          v-model="newEmployee.DOMICIL"
        ></b-input>
      </b-field>
      <b-field label="Referencia (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Casa azul"
          expanded
          icon="file-outline"
          v-model="newEmployee.REFERENCIA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número exterior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 250"
          expanded
          icon="counter"
          v-model="newEmployee.NUM_EXTER"
        ></b-input>
      </b-field>
      <b-field
        label="Número interior (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 10"
          expanded
          icon="counter"
          v-model="newEmployee.NUM_INTER"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Municipio (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newEmployee.NOMBRE_MUNICIPIO"
        ></b-input>
      </b-field>
      <b-field
        label="Clave de municipio (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 001"
          expanded
          icon="city-variant-outline"
          v-model="newEmployee.MUNICIPIO"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Estado (Opcional)" expanded label-position="inside">
        <b-select
          placeholder="Ejemplo: Nuevo León"
          expanded
          icon="sign-real-estate"
          v-model="newEmployee.ESTADO"
        >
          <option value="" disabled>Seleccione un estado</option>
          <option
            v-for="singleState in states"
            :value="singleState.CLAVE"
            :key="singleState.NOMBRE"
          >
            {{ singleState.NOMBRE }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Clave de la localidad (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 01"
          expanded
          icon="sign-real-estate"
          v-model="newEmployee.LOCALIDAD"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field expanded label-position="inside">
        <template slot="label">
          Nombre de la Colonia (Opcional)
          <b-tooltip
            multilined
            label="El listado de colonias disponibles aparece una vez llenado el código postal"
          >
            <b-icon
              size="is-small"
              icon="help-circle-outline"
              custom-class="input-help-icon"
            ></b-icon>
          </b-tooltip>
        </template>
        <b-autocomplete
          :data="filteredColonies"
          field="colony"
          open-on-focus
          placeholder="Ejemplo: Del Norte"
          expanded
          icon="home-group"
          v-model="newEmployee.NOMBRE_COLONIA"
          clearable
          @select="(option) => (newEmployee.NOMBRE_COLONIA = option.colony)"
        >
          <template #empty>No se encontraron colonias</template>
        </b-autocomplete>
      </b-field>

      <b-field
        label="Clave de la colonia (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0001"
          expanded
          icon="sign-real-estate"
          v-model="newEmployee.COLONIA"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de contacto empresarial</h3>

    <b-field grouped class="special-margin">
      <b-field label="Correo (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: correo@mail.com"
          expanded
          icon="at"
          v-model="newEmployee.EMAIL"
        ></b-input>
      </b-field>
      <b-field label="Teléfono (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 3333333333"
          expanded
          icon="phone"
          v-model="newEmployee.TEL_MOVIL"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de contacto personal</h3>

    <b-field grouped class="special-margin">
      <b-field label="Correo (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: correo@mail.com"
          expanded
          icon="at"
          v-model="newEmployee.EMAIL_P"
        ></b-input>
      </b-field>
      <b-field label="Teléfono (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: 3333333333"
          expanded
          icon="phone"
          v-model="newEmployee.TEL_MOVIL_P"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Información de contacto de famliar</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nombre del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Jim"
          expanded
          icon="card-account-details-outline"
          v-model="newEmployee.FAM_NOMBRE"
        ></b-input>
      </b-field>
      <b-field
        label="Apellido paterno del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Halpert"
          expanded
          icon="human-male-boy"
          v-model="newEmployee.FAM_APELLIDOP"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Apellido materno del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Beesly"
          expanded
          icon="human-female-girl"
          v-model="newEmployee.FAM_APELLIDOM"
        ></b-input>
      </b-field>
      <b-field
        label="Teléfono del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0000000000"
          expanded
          icon="phone"
          v-model="newEmployee.FAM_TELEFONO"
          v-cleave="masks.customPhone"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Correo del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: correo@mail.com"
          expanded
          icon="at"
          v-model="newEmployee.FAM_CORREO"
        ></b-input>
      </b-field>
      <b-field
        label="Parentesco del familiar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Esposa"
          expanded
          icon="human-male-female"
          v-model="newEmployee.FAM_PARENTESCO"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="employeeInformation">
      <b-button type="is-success" @click="saveEmployee(1)">
        Actualizar empleado
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!employeeInformation">
      <b-button type="is-success" @click="saveEmployee(0)"
        >Guardar empleado</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import States from "../../../data/states";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddEmployeeModal",
  props: ["employeeInformation"],
  directives: { cleave },
  components: {},
  data() {
    return {
      moment: moment,
      newEmployee: {
        FECHA_INICIO: new Date(),
        FECHA_NACIMIENTO: new Date(),
        CVE_PERS: "",
        PUESTO: "",
        NOMBRE: "",
        PATERNO: "",
        MATERNO: "",
        CANTIDAD_MAXIMA_PRESTAMO: 0,
        CURP: "",
        RFC: "",
        PRECIO_HORA_EXTRA: 0,
        SEXO: "M",
        CP: "",
        DOMICIL: "",
        REFERENCIA: "",
        NUM_EXTER: "",
        NUM_INTER: "",
        NOMBRE_MUNICIPIO: "",
        MUNICIPIO: "",
        ESTADO: "",
        LOCALIDAD: "",
        COLONIA: "",
        NOMBRE_COLONIA: "",
        EMAIL: "",
        EMAIL_P: "",
        TEL_MOVIL: "",
        TEL_MOVIL_P: "",
        FAM_NOMBRE: "",
        FAM_APELLIDOP: "",
        FAM_APELLIDOM: "",
        FAM_TELEFONO: "",
        FAM_CORREO: "",
        FAM_PARENTESCO: "",
        TIPO: "",
        USER: undefined,
        COMISION_COMPRA: 0,
        TIPO_COMISION_COMPRA: "",
        COMISION_VENTA: 0,
        TIPO_COMISION_VENTA: "",
        SALARIO_FIJO: 0,
        SALARIO_ES_PRESTAMO: "N",
        HORARIO_ENTRADA: undefined,
        HORARIO_SALIDA: undefined,
        HORARIO_DESAYUNO: undefined,
        HORARIO_COMIDA: undefined,
        HORARIO_CENA: undefined,
        META_VENTAS: 0,
      },
      currentColonies: [],
      masks: Masks,
      states: States,
      errors: {
        CVE_PERS: false,
        PUESTO: false,
        NOMBRE: false,
        PATERNO: false,
        MATERNO: false,
        CURP: false,
        RFC: false,
      },
    };
  },
  async mounted() {
    // Fill the article information in form fields
    if (this.employeeInformation) {
      // Fill the form with received information
      fillObjectForm(this.employeeInformation, this.newEmployee);
      this.newEmployee._id = this.employeeInformation._id;
      if (this.employeeInformation.USER) {
        this.newEmployee.USER = this.employeeInformation.USER;
      }

      if (this.employeeInformation.HORARIO_ENTRADA) {
        this.newEmployee.HORARIO_ENTRADA = new Date(
          this.employeeInformation.HORARIO_ENTRADA
        );
      }

      if (this.employeeInformation.HORARIO_SALIDA) {
        this.newEmployee.HORARIO_SALIDA = new Date(
          this.employeeInformation.HORARIO_SALIDA
        );
      }

      if (this.employeeInformation.HORARIO_DESAYUNO) {
        this.newEmployee.HORARIO_DESAYUNO = new Date(
          this.employeeInformation.HORARIO_DESAYUNO
        );
      }

      if (this.employeeInformation.HORARIO_COMIDA) {
        this.newEmployee.HORARIO_COMIDA = new Date(
          this.employeeInformation.HORARIO_COMIDA
        );
      }

      if (this.employeeInformation.HORARIO_CENA) {
        this.newEmployee.HORARIO_CENA = new Date(
          this.employeeInformation.HORARIO_CENA
        );
      }

      if (this.employeeInformation.FECHA_INICIO) {
        this.newEmployee.FECHA_INICIO = new Date(
          this.employeeInformation.FECHA_INICIO
        );
      }

      if (this.employeeInformation.FECHA_NACIMIENTO) {
        this.newEmployee.FECHA_NACIMIENTO = new Date(
          this.employeeInformation.FECHA_NACIMIENTO
        );
      }
    }

    if (this.newEmployee.CP.length === 5) {
      let response = await this.$store.dispatch("SEARCHDIRECTIONINFORMATION", {
        zipCode: this.newEmployee.CP,
      });
      if (response.length > 0) {
        this.currentColonies = response;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveEmployee(type) {
      try {
        this.errors = validateFields(this.newEmployee, this.errors);
        if (checkValidationErrors(this.errors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEEMPLOYEE" : "EDITPERSONAL",
            this.newEmployee
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el personal",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el personal en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el personal en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el personal en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc searches for direction information when zip has the corret format
     */
    async searchForDirectionInformation(zipCode) {
      if (zipCode.length === 5) {
        let response = await this.$store.dispatch(
          "SEARCHDIRECTIONINFORMATION",
          { zipCode }
        );
        if (response.length > 0) {
          this.newEmployee.ESTADO = response[0].state_code;
          this.newEmployee.NOMBRE_MUNICIPIO = response[0].municipality;
          this.newEmployee.MUNICIPIO = response[0].municipio_code;
          this.newEmployee.LOCALIDAD = response[0].localidad_code;
          this.newEmployee.NOMBRE_COLONIA = response[0].colony;
          this.newEmployee.COLONIA = response[0].colony_code;
          this.currentColonies = response;
        }
      }
    },
    /**
     *
     * @desc checks if a user is already selected so its disabled
     */
    checkDisabledUser(userInformation) {
      for (const singleEmployee of this.employees) {
        if (userInformation._id === singleEmployee.USER) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    filteredColonies() {
      return this.currentColonies.filter((singleColony) => {
        return (
          singleColony.colony
            .toString()
            .toLowerCase()
            .indexOf(this.newEmployee.NOMBRE_COLONIA.toLowerCase()) >= 0
        );
      });
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      });
    },
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        return userWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../style/AddEmployeeModal.css";
@import "../../Global/style/Global.css";
</style>
